import { marketPlace } from '@/axios'

export default {
  uploadMarketplaceFiles (payload) {
    return marketPlace().patch('merchant/stores', payload)
  },
  getMerchantInfo () {
    return marketPlace().get('merchant/info')
  }
}
