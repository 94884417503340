<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      {{ $t('main.merchantInfo') }}
    </dashboard-page-title>
     <form @submit.prevent="save">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i>
                    {{ $t('main.merchantDetails') }}
                  </h5>
                </template>
                <b-row>
                    <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        :placeholder="$t('main.enterName')" :label="$t('main.name')"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.phone"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 0109564546"
                        :label="$t('main.phone')"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.national_id"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 454846511154"
                        :label="$t('main.nationalId')"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i>
                    {{ $t('main.merchantLinks') }}
                  </h5>
                </template>
                <b-row>
                  <b-col cols="12">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="storeActive.url"
                      validate="required"
                      name="url"
                      :placeholder="$t('main.storeLink')"
                      :label="$t('main.storeLink')"
                    />
                  </b-col>
                  <b-col md="12" class="mb-5" v-for="(item, key) in market.social_media_links" :key="key">
                    <b-form-group
                        :label-for="'URL Links'"
                        class="position-relative"
                    >
              <span class="text-danger deleteLabelButton cursor-pointer" v-if="key != 0" @click="deleteLink(key)">
                {{ $t('main.delete') }}
              </span>
                      <b-input-group class="d-flex gap_1">
                        <validation-provider
                            #default="{ errors }"
                            :name="`URL Link ${key + 1}`"
                            :rules="{ required:true }"
                            class="flex-grow-1"
                        >
                          <b-form-input
                              id="mm"
                              v-model="item.link"
                              :class="['text-black',{ 'is-invalid': errors.length > 0, }]"
                              :placeholder="'Ex: https://www.google.com'"
                              :disabled="!item.selectSocial"
                          />
                        </validation-provider>
                        <template #prepend>
                          <b-dropdown
                              :text="item.selectSocial ? item.selectSocial : 'Choose  '"
                              class="selectWithInput iq-border-radius-5 bg-white border"
                              variant="white"
                          >
                            <b-dropdown-item v-for="(i, keyLink) in filterLinks" :key="keyLink" @click="item.selectSocial = i">
                              {{i}}
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="allLinks.length !== market.social_media_links.length"><span class="text-warning cursor-pointer" @click="addNewLink">
                  {{ $t('main.addAnotherPlatform') }}
                  </span></b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-store"></i>
                    {{ $t('main.storeDetails') }}
                  </h5>
                </template>
                <b-row>
                  <b-col  cols="12">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        :placeholder="$t('main.enterName')"
                        :label="$t('main.name')"
                    />
                  </b-col>
                  <b-col md="12">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.address"
                        :validate="'required'"
                        name="id"
                        :disabled="true"
                        :label="$t('main.address')"
                        :placeholder="$t('main.address')"
                    />
                  </b-col>
                  <b-col md="12">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="storeActive.vat_no"
                      :validate="'required|digits:15'"
                      name="vat_no"
                      :label="$t('main.vat_no')"
                      :placeholder="$t('main.vat_no')"
                    />
                  </b-col>
                  <b-col md="12">
                    <textarea-form
                      :placeholder="$t('main.description')"
                      :label="$t('main.aboutStore')"
                      v-model="storeActive.about"
                    name="about" validate="required"></textarea-form>
                  </b-col>
                  <b-col md="12">
                    <textarea-form
                    :placeholder="$t('main.termsAndPolicy')"
                    v-model="storeActive.policies"
                    :label="$t('main.termsAndPolicy')"
                    name="policies" validate="required"></textarea-form>
                  </b-col>
<!--                  <b-col md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.category.name"
                        :validate="'required'"
                        name="id"
                        :disabled="true"
                        label="نوع المتجر"
                        placeholder="نوع المتجر"
                    />
                  </b-col>-->
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>
                    {{ $t('main.images') }}
                  </h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        :label="$t('main.logo')"
                        nameOfImage="image.jpg"
                        @cropper-save="saveLogoImage"
                        :progressLoading="progressLogo"
                        :multi="false"
                        :imageUrl="storeActive.logo"
                    />
                  </b-col>
                  <!-- <b-col md="12" class="mb-4">
                    <cropper-images
                        label="غلاف المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCoverImage"
                        :progressLoading="progressCover"
                        :showProgress="true"
                        :multi="false"
                        :imageUrl="storeActive.cover"
                    />
                  </b-col> -->
                </b-row>
              </b-card>
            </div>

            <div class="form-actions">
              <b-button variant="outline-primary" @click="$router.back()">
              {{ $t('main.back') }}
              </b-button>
              <b-button v-if="$route.name !== 'showProduct'" variant="primary" type="submit" :disabled="loadingSubmit">
                <span v-if="!loadingSubmit">
                {{ $t('main.save') }}
                </span>
                <spinner-loading v-else text="loading"></spinner-loading>
              </b-button>
            </div>
      </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
export default {
  mixins: [mainInfoMixins],
  components: { },
  mounted () {
    core.index()
  },
  watch: {
  },
  data () {
    return {
      allLinks: [
        'Website',
        'Facebook',
        'Instagram'
      ],
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      market: {
        logo: '',
        cover: '',
        social_media_links: [
          {
            selectSocial: '',
            link: ''
          }
        ],
        about: '',
        policies: ''
      },
      loadingSubmit: false,
      progressCover: 0,
      progressLogo: 0,
      progressCommercialRegistry: 0,
      progressVatCertificate: 0
    }
  },
  computed: {
    filterLinks () {
      var newLinksArr = [...this.allLinks]
      this.market.social_media_links.forEach(e => {
        if (newLinksArr.includes(e.selectSocial)) {
          var socialIndex = newLinksArr.findIndex(social => social === e.selectSocial)
          console.log(socialIndex)
          newLinksArr.splice(socialIndex, 1)
        }
      })
      return newLinksArr
    }
  },
  methods: {
    // getStoreInfo () {
    //   settingsServices.getMerchantInfo().then(res => {
    //     console.log(res.data)
    //   })
    // },
    saveLogoImage (file) {
      const formData = new FormData()
      formData.append('image', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressLogo = percent
        }
      }
      this.commonUploadFiles(formData, options, 'merchant/upload/merchant').then(res => {
        this.market.logo = res.data
        this.showSuccessUploadFile()
      })
    },
    saveCoverImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressCover = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.market.cover = res.data
        this.showSuccessUploadFile()
      })
    },
    addNewLink () {
      this.market.social_media_links.push({
        selectSocial: '',
        link: ''
      })
    },
    deleteLink (key) {
      this.market.social_media_links.splice(key, 1)
    },
    save () {
      this.loadingSubmit = true
      console.log('storeActive', this.storeActive)
      settingsServices.uploadMarketplaceFiles({
        logo: this.storeActive.logo,
        cover: this.storeActive.cover,
        social_media_links: this.storeActive.social_media_links,
        about: this.storeActive.about,
        policies: this.storeActive.policies,
        url: this.storeActive.url,
        vat_no: this.storeActive?.vat_no || ''
      }).then(res => {
        core.showSnackbar('success', 'Data Updated Successfully')
        this.getInfo()
      }).finally(() => {
        this.loadingSubmit = false
      })
    }
  },
  created () {
    // this.getStoreInfo()
  }
}
</script>
<style>
.deleteLabelButton {
  position: absolute;
  left: 10px;
  top: -30px;
}
</style>
